<template>
  <div class="screen-container">
    <div class="screen-container-header flex-between">
      <div class="header-left flex-first">
        <img src="./assets/images/icon-1.png" class="icon" alt="">
        <span class="font-white font-family-base font-20 ml16 cursor-pointer">
          {{ area_info.city }}
          -{{ area_info.district }}
          -{{ area_info.street }}
        </span>
        <!--        <div class="el-icon-caret-bottom" style="font-size:20px;color: #fff;"></div>-->
      </div>
      <div class="header-center text-center flex-center font-white font-28 font-weight-700">
        电动自行车充电站 AI智慧安全监管平台
      </div>
      <div class="header-right font-white font-16 flex-end">
        <div class="ml16">{{ current_date }}</div>
        <!--        <div class="ml8 flex-first">-->
        <!--          <img src="./assets/images/icon-3.png" class="header-right-icon" alt="">-->
        <!--          <span class="ml4">晴</span>-->
        <!--        </div>-->
        <!--        <div class="ml16 flex-first cursor-pointer" @click="toggleFullScreen">-->
        <!--          <img src="./assets/images/icon-5.png" class="header-right-icon" alt="">-->
        <!--        </div>-->
      </div>
    </div>
    <div class="camera-content  margin-center flex-between">
      <div class="camera-content-header flex-first containers96 margin-center mt8">
        <div class="cursor-pointer" @click="$funcs.goBack()"><i class="el-icon-arrow-left"></i>返回</div>
        <div class="form-select flex-between ml16">
          <div class="ml3p"><span class="mr16">社区</span> | <span class="font-white ml16">全部</span></div>
          <!--            <i class="el-icon-caret-bottom mr3p" style="font-size: 32px;"></i>-->
          <div class="form-select-list">

          </div>
        </div>
        <div class="page flex-first">
          <div class="page-item" :class="item===page_data.current_page?'active-page-item':''" @click="nextPage(item)"
               v-for="item in page_data.total_page">
            {{ item }}
          </div>
        </div>
      </div>
      <div class="containers96 margin-center mt24 flex-between">
        <div v-for="camera in cctv_list"
             :key="camera.SerialNumber"
             class="camera-item mb14">
          <YSLivePlayer
              v-if="camera.config.LiveProtocol==='ys'"
              :SerialNumber="camera.serialNumber"
              :AccessToken="ys_access_token"
              :width="cctv_width"
              :height="cctv_height"/>
          <WebRTCVideoV2
              v-else-if="camera.config.LiveProtocol === 'WebRTC-WAPA'"
              :title="camera.serialNumber +' '+camera.name"
              :signal-request="webRTCSignalRequestWithWAPA"
              :data="{...camera}"
              :width="cctv_width"
              :height="cctv_height"></WebRTCVideoV2>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 16px;"></div>
  </div>
</template>

<script>
import YSLivePlayer from "./components/YSLivePlayer.vue";
import WebRTCVideoV2 from "@/views/Screen/components/WebRTCVideoV2.vue";
import HttpUtil, {ContentType} from "@/utils/HttpUtil";

let _this;
export default {
  name: "CCTV",
  computed: {},
  components: {
    WebRTCVideoV2,
    YSLivePlayer
  },
  data() {
    return {
      current_date: '',
      street_code: '440105004',
      area_info: {},
      street_list: [],
      cctv_list: [],
      ys_access_token: '',
      is_load_cctv: false,
      cctv_width: 0,
      cctv_height: 0,
      page_data: {
        limit: 4,
        total_page: 0,
        current_page: 1,
        total: 0
      },
    }
  },
  created() {
    console.log("cctv.created");

    _this = this;
    _this.street_code = _this.$route.query.street;
    setInterval(() => {
      _this.current_date = _this.$utils.formatDate(new Date().getTime(), "Y-m-d H:i:s");
    }, 1000);

    _this.getChargeAreaInfo();
    _this.getYSAccessToken(() => {
      _this.getCCTVList();
      _this.initCCTVWidth();
    });
  },
  methods: {
    getCCTVList(current_page = 1) {
      let param = {};
      // console.log(current_page);
      param.street_code = _this.street_code;
      param.page = current_page;
      param.limit = _this.page_data.limit;
      _this.$get("/megadata/street/v2/monitor/lst", param).then(res => {
        if (res.code === 0) {
          _this.cctv_list = [];
          for (let c of res.data) {
            if (c.config) c.config = JSON.parse(c.config);
            //如果协议是萤石云协议并且还没请求过萤石云授权码，则进行异步请求
            _this.cctv_list.push(c);
          }

          _this.is_load_cctv = true;
          _this.page_data.total_page = res.pages;
          _this.page_data.total = res.count;
        }
      }).catch(err => {
        console.log(err)
      });
    },
    //异步请求获取萤石云授权码
    getYSAccessToken(_cb_) {
      _this.$get("/sdk/ys/access_token").then((r) => {
        if (r.code !== 0) {
          setTimeout(() => {
            _this.getYSAccessToken(_cb_);
          }, 5000);
        }
        _this.ys_access_token = r.data.accessToken;
        if (typeof _cb_ === "function") _cb_();
      });
    },
    async webRTCSignalRequestWithWAPA(offer, device) {
      // 向信令服务器发送请求
      const apiUrl = `https://${device.config.IP}:${device.config.port}/rtc/v1/play/`;
      const params = {
        "api": apiUrl,
        "channelid": device.config.channelid,
        "clientip": null,
        "nettype": device.config.nettype,
        "sdp": offer.sdp,
        "stream": device.serialNumber,
        "streamurl": `webrtc://${device.config.IP}/play/${device.serialNumber}`,
        "tid": Number(parseInt(new Date().getTime() * Math.random() * 100)).toString(16).slice(0, 7),
        "username": device.config.username,
        "password": device.config.password,
      };
      const response = await HttpUtil.POST(apiUrl, params, ContentType.JSON);
      if (response && response.code === 0) {
        return response.sdp;
      } else {
        console.error('无法从信令服务器获取 SDP');
      }
      return undefined;
    },
    getChargeAreaInfo() {
      let param = {
        street_code: _this.street_code,
      }
      _this.$get('/megadata/street/Summary', param).then(res => {
        if (res.code === 0) {
          _this.area_info = res.data;
        }
      }).catch(err => {
      });
    },
    initCCTVWidth() {
      const window_width = window.innerWidth * 0.96;
      const cctv_width = Number((window_width * 0.49).toFixed(0));
      const cctv_height = Number(((cctv_width * 555) / 924).toFixed(0));
      _this.cctv_width = cctv_width;
      _this.cctv_height = cctv_height;
    },
    nextPage(pages) {
      _this.cctv_list = [];
      _this.page_data.current_page = pages;
      _this.getCCTVList(pages);
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        _this.is_full_screen = true;
        _this.openFullScreen();
      } else {
        _this.is_full_screen = false;
        _this.closeFullScreen();
      }
    },
    openFullScreen() {
      let elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen();
      }
    },
    closeFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    },
  }
}
</script>
<style src="./assets/css/Base.css"></style>
<style scoped>
.camera-content {
  /*overflow: hidden;*/
}

.camera-content .camera-content-header {
  height: 48px;
  color: #3EB9FF;
  font-family: Alibaba;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
}

.camera-content .camera-content-header .form-select {
  width: 270px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #3EB9FF;
  background: #0A1738;
  box-shadow: 0px 0px 24px 0px #5987FC inset;
  color: #3EB9FF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.333px;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
}

.camera-content .camera-content-header .form-select .form-select-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 270px;
}

.camera-content .camera-item {
  //height: 555px; overflow: hidden;
  /*height: 300px;*/
  border-radius: .625rem;
  border: .0625rem solid #3eb9ff;
  padding: 1px;
  box-shadow: inset 0 0 1.5rem 0 #5987fc;
  background-color: rgba(10, 23, 56, .8);
}

.page {
  margin-left: 24px;
}

.page .page-item {
  width: 24px;
  height: 24px;
  background: #3EB9FF;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.333px;
  text-align: center;
  cursor: pointer;
  margin-right: 8px;
}

.page .page-item:hover {
  background: #fff;
  color: #3EB9FF;
}

.page .active-page-item {
  background: #fff;
  color: #3EB9FF;
}


</style>